exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorien-alphabetisch-js": () => import("./../../../src/pages/kategorien-alphabetisch.js" /* webpackChunkName: "component---src-pages-kategorien-alphabetisch-js" */),
  "component---src-pages-kategorien-illustriert-js": () => import("./../../../src/pages/kategorien-illustriert.js" /* webpackChunkName: "component---src-pages-kategorien-illustriert-js" */)
}

